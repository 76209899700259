const $prevArrow = '<button type="button" class="slick-prev--custom"><span class="u-with-icon">'
    + '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="u-icon is-outline">'
    + '<path class="u-icon__path" d="M15 19L8 12L15 5" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
    + '</svg>'
    + '<span class="display-none" aria-disabled="true">vorherige</span></span></button>';
 
 const $nextArrow = '<button type="button" class="slick-next--custom"><span class="u-with-icon">'
    + '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="u-icon is-outline">'
    + '<path class="u-icon__path" d="M9 5L16 12L9 19" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
    + '</svg>'
    + '<span class="display-none" aria-disabled="true">nächste</span></span></button>';

// // slick-slider
// ---------------------------------------------------------------------------
const $tourSlider = $(".js-tour-slider");
const $tourSliderOptions = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: true,
  arrows: true,
  adaptiveHeight: false,
  prevArrow: $prevArrow,
  nextArrow: $nextArrow
};

// On init
//$tourSlider.on('init', function(event, slick, direction){
//  console.info("tourSlider inited");
//});

const $gallerySliderSlider = $(".js-gallery-slider");
const $gallerySliderSliderOptions = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  dots: true,
  arrows: true,
  prevArrow: $prevArrow,
  nextArrow: $nextArrow,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        //infinite: false,
        dots: false,
        arrows: true,
        prevArrow: $prevArrow,
        nextArrow: $nextArrow
      }
    }
  ]
};

const $jobsSlider = $(".js-jobs-slider-thumbs");
const $jobsSliderOptions = {
  slidesToShow: 2,
  slidesToScroll: 2,
  infinite: false,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false
      }
    }
  ]
};

const $ergoSlider = $(".js-slider-ergo");
const $ergoSliderOptions = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrows: false,
  adaptiveHeight: false,
  draggable: false,
  swipe: false,
  touchMove: false
};

module.exports = {
  tourSlider: $tourSlider,
  tourSliderOptions: $tourSliderOptions,
  gallerySlider: $gallerySliderSlider,
  gallerySliderOptions: $gallerySliderSliderOptions,
  jobsSlider: $jobsSlider,
  jobsSliderOptions: $jobsSliderOptions,
  ergoSlider: $ergoSlider,
  ergoSliderOptions: $ergoSliderOptions
};
