'use strict';

const preloader = document.querySelector('.preloader');

export function loaderOnload() {
  window.setTimeout(function () {
    preloader.classList.add('loaded');
  }, 0);
};

window.addEventListener('load', (event) => {
  loaderOnload();
});