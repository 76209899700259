"use strict";


// we-Branding unsichtbar setzen
function removeWeBranding() {
  var weBranding = $('div[style*="!important"]');
  weBranding.removeAttr('style').addClass('u-hidden');
}

window.addEventListener('load', (event) => {
  removeWeBranding();
});
