// accordion
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { mq } from './variables.js';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

gsap.utils.toArray(".c-accordion__header").forEach(function(a) {
  a.addEventListener("click", function(e) {
    //e.preventDefault();
    
    var pos_origin = e.target.getAttribute("data-pos");
    var pos = (pos_origin - 1);
    //var activePanel = document.querySelectorAll("details[open]");
    
    let offsetY = 50;
    
    if (mq.lg.matches) {
        offsetY = 100;
    }

    gsap.to(window, {
        duration: .5, 
        scrollTo: {
            y: "#acc-panel-" + pos, 
            offsetY: offsetY
        }
    });
    
    // close other panels
    var $parent = $(this).parent();    
    $( "details" ).not($parent).each(function( i ) {
        $(this).removeAttr('open', '');
    });
    
    console.log('offsetY: ' + offsetY);
  });
});