// change logo

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    id: "logo",
    trigger: ".c-headline",
    start: 'bottom top',
    //endTrigger: "#site-footer",
    //end: '40% top',
    endTrigger: ".c-footer__body",
    end: 'top top',
    scrub: true,
    markers: false,
    toggleClass: {
        className: 'is-positive', 
        targets: '.c-site-logo.is-negative'
    }
    //events: onEnter onLeave onEnterBack onLeaveBack
    //toggleActions:"reverse restart restart reverse",
    //options: play, pause, resume, reset, restart, complete, reverse,none
});

ScrollTrigger.create({
    id: "nav-main-toggle",
    trigger: ".c-headline",
    start: '10% top',
    endTrigger: ".c-footer__body",
    end: 'top top',
    scrub: true,
    markers: false,
    toggleClass: {
        className: 'is-positive-gsap', 
        targets: '.c-nav-main-toggle.is-negative'
    }
});
