import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

window.Fancybox = Fancybox;

Fancybox.bind("[data-fancybox]", {
  infinite: false,
});

//
// fancybox
// ---------------------------------------------------------------------------
// Open thumbs view straight away for demo purposes
//$.fancybox.defaults.thumbs.autoStart = true;

/*
$().fancybox({
  selector : '.js-tour-thumbs-slider .slick-slide:not(.slick-cloned)',
  backFocus : false,
  infobar: false,
  gutter: 0
});

$().fancybox({//'[data-fancybox="images"]'
  selector : '.js-gallery-slider .slick-slide:not(.slick-cloned) a',
  backFocus : false, 
  afterShow : function( instance, current ) {
    current.opts.$orig.closest(".slick-initialized").slick('slickGoTo', parseInt(current.index), true);
  }
});  

$('[data-fancybox="address-map"]').fancybox({
    autoSize: false,
    backFocus : false,
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 20, 
    type : 'iframe',
    toolbar  : true,
    smallBtn : false,
    iframe : {
      preload : false
    }
});

$('[data-fancybox="keyvisual"]').fancybox({
    autoSize: false,
    backFocus : false,
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 20, 
    type : 'iframe',
    toolbar  : true,
    smallBtn : false,
    iframe : {
      preload : false
    }
});

$().fancybox({
  selector : '.js-news-img',
  backFocus : false,
  infobar: false,
  gutter: 0
});

$().fancybox({
  selector : '.js-service-gallery'
});

$().fancybox({
  selector : '.js-service-panorama',
  //autoSize: false,
  //backFocus : false,
  type : 'iframe',
  iframe : {
    preload : false
  }
});

$().fancybox({
selector : '.js-modal'
});

$().fancybox({
  selector : '.js-modal-page',
  parentEl : document.getElementsByClassName('js-modal-wrapper'),
  //autoSize: false,
  //backFocus : false,
  type : 'iframe',
  iframe : {
    preload : false,
  }
});

// ================================================
// Attach custom click event on cloned elements, 
// trigger click event on corresponding link.
// Note: This will work for all sliders on the page
// ================================================
$(document).on('click', '.slick-cloned', function(e) {
  var $slides = $(this)
    .parent()
    .children('.slick-slide:not(.slick-cloned)');

  $slides
    .eq( ( $(this).attr("data-slick-index") || 0) % $slides.length )
    .trigger("click.fb-start", { $trigger: $(this) });

  return false;
});
 * 
 */