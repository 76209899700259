// global vars
import { mq } from './variables.js';//,cp, apSite

const logo = document.querySelector(".c-site-logo");

const btnToggle = document.getElementById('js-nav-main-toggle');
if (btnToggle !== null){
const linesButton = document.getElementById('js-line-button');
const navMain = document.querySelector('.c-nav');
const menuItems_Level1 = document.querySelectorAll('.menu-item--level1');
const menuItems_Level2 = document.querySelectorAll('.menu-item--level2');
const menuItems_Level3 = document.querySelectorAll('.menu-item--level3');
let timer1, timer2;

const isDescendant = (el, parentId) => {
  let isChild = false;

  if (el.id === parentId) { //is this the element itself?
    isChild = true;
  }

  while (el = el.parentNode) {
    if (el.id == parentId) {
      isChild = true;
    }
  }
  
  return isChild;
};

// navigation outside
document.addEventListener('click', event => {
  const parentId = 'nav_main';

  if (!isDescendant(event.target, parentId)) {
    const navMain = document.getElementById(parentId);
    const li = navMain.querySelectorAll('li');
    
    li.forEach(function(element){
        element.classList.remove('is-open');//, 'is-active'
        element.querySelector('a').setAttribute('aria-expanded', "false");
    });
  }
});

//console.log(linesButton.classList);
btnToggle.addEventListener("click", function(event){
    if(this.getAttribute('aria-expanded') === "true") {
        this.setAttribute('aria-expanded', "false");
        linesButton.classList.remove('close');
        
        if (!mq.md.matches) {
            logo.classList.remove('is-negative-menu');
            
        }
    } else {
        this.setAttribute('aria-expanded', "true");                
        linesButton.classList.add('close');
        
        if (!mq.md.matches) {
            
            timer1 = setTimeout(function(event){
                logo.classList.add('is-negative-menu');
            }, 250);
        }
    }
    event.preventDefault();
});

function removeOpen(elm, target) {
    if (elm.id !== target.id) {
        //console.log(elm.id + ' :: ' + target.id);
        elm.classList.remove('is-open', 'is-active');
        //console.log(e.classList);
        if (elm.querySelector('a')) {
            elm.querySelector('a').setAttribute('aria-expanded', "false");
        }
    }
}

Array.prototype.forEach.call(menuItems_Level1, function(el, i){
    el.querySelector('a').addEventListener("click", function(event){
        let parentUl = this.parentNode.parentNode;
        let siblings = parentUl.querySelectorAll("li");
        //console.log(siblings);
        //console.log(this.parentNode);
        
        siblings.forEach(element => removeOpen(element, this.parentNode));
        
        if(this.parentNode.classList.contains('is-active')) {
            this.parentNode.classList.remove('is-active');
        } else {
            this.parentNode.classList.add('is-active');
        }
        
        if(this.parentNode.classList.contains('has-submenu')) {
            if(this.getAttribute('aria-expanded') === "true") {
                this.parentNode.classList.remove('is-open');
                //this.parentNode.classList.remove('is-active');
                this.setAttribute('aria-expanded', "false");
            } else {
                this.parentNode.classList.add('is-open');
                this.setAttribute('aria-expanded', "true");                
            }            
            event.preventDefault();
        }
    });
});

Array.prototype.forEach.call(menuItems_Level2, function(el, i){    
    el.querySelector('a').addEventListener("click", function(event){
        let parentUl = this.parentNode.parentNode.parentNode;
        let siblings = parentUl.querySelectorAll("li");
        //console.log(siblings);
        //console.log(parentUl);

        siblings.forEach(element => removeOpen(element, this.parentNode.parentNode));
        
        if(this.parentNode.parentNode.classList.contains('is-active')) {
            this.parentNode.parentNode.classList.remove('is-active');
        } else {
            this.parentNode.parentNode.classList.add('is-active');
        }
        
        if(this.parentNode.parentNode.classList.contains('has-submenu')) {
            if(this.getAttribute('aria-expanded') === "true") {
                this.parentNode.parentNode.classList.remove('is-open');
                //this.parentNode.classList.remove('is-active');
                this.setAttribute('aria-expanded', "false");
            } else {
                this.parentNode.parentNode.classList.add('is-open');
                this.setAttribute('aria-expanded', "true");                
            }
            
            event.preventDefault();
        }
    });
});
}