//import '../scss/app.scss';

require('./fancybox4.js');
require('lazyload');
require('./animation_logo.js');
require('./animation_header.js');
require('./fly-out-menu.js');
require('./weBranding.js');
require('./loader.js');

lazyload();
