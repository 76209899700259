import '../scss/app.scss';

require('./common.js');

require('slick-carousel');
require('./slick-slider.js');
require('./animation_accordion.js');
require('./accessible-tabs.js');

$(function () {
  //objectFitImages();
  function swappity($img){
    $img
        // Find the parent <picture> tag of img
        .closest('picture')
        // Find <source> tags with data-lazy-srcset attribute
        .find('[data-lazy-srcset]')
        // Copy data-lazy-srcset to srcset
        .each(function (i, source) {
            let $source;
            $source = $(source);
            $source.attr('srcset', $source.data('lazy-srcset'));
        }); 
  }

  //jQuery( '.js-fp-kv-slider' ).on( 'lazyLoaded', function( evt, slick, $img ) {
  //  console.log('yo this doen\'t seem to be working');
  //});
  
  jQuery( '.js-fp-kv-slider' ).on('lazyLoadError', function(event, slick, image, imageSource){
    console.log('probs loading ', imageSource);
  });

  jQuery( '.js-fp-kv-slider' ).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var img = slick.$slides.get(nextSlide).querySelector('img');
    //console.log(slick.$slides.get(nextSlide));
    swappity(jQuery(img));
  });
  
  //and we need to refire picturefill so IE gets the goodies once the srcset is set
  //jQuery( '.js-fp-kv-slider' ).on('afterChange', function(event, slick, currentSlide){ picturefill({ reevaluate: true }); });

  $(".js-fp-kv-slider").slick({
    slidesToShow: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2000,
    cssEase: 'ease-in-out',
    fade: true,
    lazyLoad: 'ondemand',
    pauseOnHover: false
  });
});