// css variables
const roofTopX = getComputedStyle(document.documentElement)
    .getPropertyValue('--roofTopX');
const roofTan = getComputedStyle(document.documentElement)
    .getPropertyValue('--roofTan');
const serviceN = getComputedStyle(document.documentElement)
    .getPropertyValue('--service-n');
const serviceD = getComputedStyle(document.documentElement)
    .getPropertyValue('--service-d');
const step_2 = getComputedStyle(document.documentElement)
    .getPropertyValue('--step-2');
const maxWidth = getComputedStyle(document.documentElement)
    .getPropertyValue('--max-width');
const bpMdX = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-md-x');
const bpMd = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-md');
const bpLg = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-lg');
const bpMg = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-mg');
//const headlineHeightFactor = getComputedStyle(document.documentElement)
//    .getPropertyValue('--headlineHeightFactor');

//custom properties
let cp = {
    roofTopX: roofTopX,
    roofTan: roofTan,
    serviceN: serviceN,
    serviceD: serviceD,
    step_2: step_2,
    maxWidth: maxWidth,
    //headlineHeightFactor: headlineHeightFactor,
};

// media queries
let mq = {
    md: window.matchMedia('(min-width: ' + bpMd + ')'),
    mdX: window.matchMedia('(min-width: ' + bpMdX + ')'),
    lg: window.matchMedia('(min-width: ' + bpLg + ')'),
    mg: window.matchMedia('(min-width: ' + bpMg + ')')
};

export { cp, mq };