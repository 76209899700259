// fixed header

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    id: 'header',
    start: 'top top',
    //end: 'bottom 100%',
    //endTrigger: "#site-footer",
    //end: triggerEndSidebar + " top",
    
    endTrigger: ".c-footer__body",
    markers: false,
    toggleClass: {
        className: 'is-scrolled', 
        targets: '.js-site-header'
    }
});